import Micromodal from 'micromodal'

import Utils from '../../helpers/Utils'
import EventEmitter from '../../helpers/EventEmitter'
import Autocomplete from '../Autocomplete'
import SelectGrape from '../SelectGrape'
import ReelManager from './ReelManager'
import SelectedMix from './SelectedMix'

/**
 * @class SlotMachine
 */
export default class SlotMachine {
  constructor () {
    // Store reelManagers
    this.reelManagers = []
    this.reelIdentifiers = ['recipe', 'wine']

    let els = document.querySelectorAll('.o-js-slot-container')
    Utils.forEach(els, (index, slot) => {
      let reelManager = new ReelManager(slot, this.reelIdentifiers[index])
      this.reelManagers.push(reelManager)
    })

    // Useful properties for search
    this.data = []
    this.resetForm = false
    this.globalData = []
    this.page = 1
    this.nbResultsPerPage = 5
    this.maxPagesVisible = 3
    this.nbEls = 20
    this.selectedMix = new SelectedMix()
    this.slotPinned = false
    this.language = document.querySelector('html').getAttribute('lang')
    this.searchRoute = (this.language === 'fr') ? 'rechercher' : 'search'
    this.pagingDirection = '' // prev or next, are we loading prev or next page ?
    this.slotMachineDirection = '' // next or prev, are we displaying prev or next element in slot machine ?
    this.lastSlotMachineDirection = '' // next or prev, were we displaying prev or next element in slot machine ?
    this.firstPageIsVisible = false
    this.slotMachinePosition = '' // up or down, are we in the up or down part of the slot machine ?
    this.shouldEmptyAutocompletes = true
    this.pinnedMix = false // used to know if we are loading page with pinned mix

    // HTML elements
    this.setEl()
    this.setHTMLElements()

    // Rate SVG stars
    this.filledStar = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>'
    this.unFilledStar = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg>'

    // Autocomplete
    this.autocompletes = new Autocomplete()

    // SelectGrape
    this.selectGrape = new SelectGrape()
  }

  setEl () {
    this.el = document.querySelector('.o-js-slot-machine')
    this.form = document.querySelector('.o-js-search-form')
  }

  setHTMLElements () {
    this.searchBtn = document.querySelectorAll('.o-js-search-btn')
    this.searchBtns = document.querySelectorAll('.o-js-search-buttons')
    this.searchInputs = document.querySelectorAll('.o-home .o-modal__content input')
    this.autocomplete = document.querySelectorAll('.o-js-autocomplete_home')
    this.stars = document.querySelector('.o-js-rate-stars')
    this.rateTitle = document.querySelector('.o-js-rate-title')
    this.pins = document.querySelectorAll('.o-js-slot-pin')
    this.resetBtn = document.querySelector('.o-js-sticky-nav__reset')
    this.langBtn = document.querySelector('.o-js-sticky-nav__lang')
    this.homeLogo = document.querySelector('.o-js-home-logo')
    this.placesClear = document.querySelector('.o-js-regions-criteria-clear')
    this.agreementSuggestions = document.querySelector('.o-js-home-mixes')
    this.searchResults = (this.el) ? document.querySelector('.o-js-search-results').parentNode : false
    this.recipeId = document.getElementById('search_recipe_id')
    this.wineId = document.getElementById('search_wine_id')
    this.wineVintage = document.getElementById('search_wine_vintage')
    this.millesimeInput = document.querySelector('.o-js-search-millesime')
  }

  resetPropertiesHTMLElements () {
    let els = document.querySelectorAll('.o-js-slot-container')
    Utils.forEach(els, (index, slot) => {
      this.reelManagers[index].el = slot
      this.reelManagers[index].listenToEvents()
      this.reelManagers[index].reel.container = slot
      this.reelManagers[index].reel.setHTMLElements()
    })

    this.selectedMix.setHTMLElements()
  }

  init () {
    // Init reelManagers
    this.reelManagers.forEach((reelManager) => {
      reelManager.init()
    })

    // Listen to click on submit els
    if (this.searchBtn.length) {
      this.handleClickOnSubmit()
    }

    if (this.searchInputs.length) {
      this.handleSearchInputChange()
    }

    // Handle drag reels and listen to click on pins
    this.handleReelsMove()
    this.handlePins()

    // Listen to add/remove favorite events
    this.listenToFavoriteEvents()

    this.autocompletes.init()
    this.handleAutocomplete()
    this.handleMillesimeInput()

    // Reset search on click on reset btn
    this.handleResetBtn()
    this.handleLangBtn()

    // Launch search with place param
    this.handlePlacesSubmit()
    this.handleClearPlaces()

    // Launch search with pinned wine or recipe
    if (Utils.findGetParameter('search[recipe_id]') || Utils.findGetParameter('search[wine_id]')) {
      this.showSearch()
      this.page = 1
      this.launchSearch(this.page)
    }

    if (Utils.findGetParameter('search[recipe_id]') && Utils.findGetParameter('search[wine_id]')) {
      this.pinnedMix = true
    }

    if ((this.recipeId.value || this.wineId.value)) {
      let page = parseInt(document.querySelector('.o-js-search-page').value)
      if (isNaN(page)) {
        this.showSearch()
        this.page = 1
        this.launchSearch(true)
      }
    }

    // Relaunch search at range update
    EventEmitter.on('updateRange', () => {
      if (this.pinnedMix) {
        this.pins[0].classList.remove('is-active')
        this.reelManagers[0].isPinned = false
        this.unPin(0)
        this.pinnedMix = false
      }
      this.page = 1
      this.launchSearch(true)
    })

    this.selectGrape.init()

    if (localStorage.getItem('selectedMixId') && !Utils.findGetParameter('search[recipe_id]') && !Utils.findGetParameter('search[wine_id]')) {
      let page = parseInt(document.querySelector('.o-js-search-page').value)
      if (!isNaN(page)) {
        this.displayStoredResults(page, parseInt(localStorage.getItem('selectedMixId')))
      }
    }

    // Disable enter validation
    this.form.addEventListener('keydown', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    })
  }

  reinitAfterSearchSaved () {
    this.setHTMLElements()
    this.resetPropertiesHTMLElements()
    this.handleResetBtn()
    this.handleLangBtn()
    this.handlePins()
    this.handleAgreementSuggestionsVisibleState()
    this.handleClearPlaces()
    this.handleClickOnSubmit()
    this.handleSearchInputChange()
    this.handleMillesimeInput()

    this.autocompletes = new Autocomplete()
    this.autocompletes.init()
    this.handleAutocomplete()

    this.selectGrape = new SelectGrape()
    this.selectGrape.init()
  }

  cleanUrl() {
    let url = document.location.href
    let urlparts = url.split('?')
    if (urlparts.length >= 2) {
      window.history.pushState('', '', urlparts[0])
    }
  }

  handleClickOnSubmit () {
    Utils.forEach(this.searchBtn, (index, searchBtn) => {
      searchBtn.addEventListener('click', () => {
        if (document.getElementById('wine-criterias').classList.contains('is-open')) {
          Micromodal.close('wine-criterias')
        } else if (document.getElementById('recipe-criterias').classList.contains('is-open')) {
          Micromodal.close('recipe-criterias')
        }

        if (document.getElementById('grape-criteria').classList.contains('is-open')) {
          Micromodal.close('grape-criteria')
        }

        if (this.wineVintage.value !== '') {
          this.page = 1
          this.launchSearch(true)
        }

        if (!this.reelManagers[0].reel.isAnimating && !this.reelManagers[1].reel.isAnimating) {
          this.reelManagers.forEach((reelManager, index) => {
            reelManager.reel.animateOneLoop(reelManager.reel.loopDuration)
          })
        }
      })
    })
  }

  handleSearchInputChange () {
    Utils.forEach(this.searchInputs, (index, searchInput) => {
      searchInput.addEventListener('change', () => {
        // Si on avait sélectionné un accord, le supprimer
        if (this.pinnedMix) {
          const inputName = searchInput.getAttribute('name')
          if (inputName.indexOf('recipe') !== -1) {
            this.pins[0].classList.remove('is-active')
            this.reelManagers[0].isPinned = false
            this.unPin(0)
            this.pinnedMix = false
          } else if (inputName.indexOf('wine') !== -1) {
            this.pins[1].classList.remove('is-active')
            this.reelManagers[1].isPinned = false
            this.unPin(1)
            this.pinnedMix = false
          }
        }
        this.page = 1
        this.launchSearch(true)
      })
    })
  }

  showSearch () {
    this.agreementSuggestions.classList.remove('is-visible')
    this.searchResults.classList.add('is-visible')
    this.el.classList.add('is-visible')
  }

  /**
   * @function handlePlacesSubmit
   * Call @function launchSearch
   * Close places modal
   */
  handlePlacesSubmit () {
    EventEmitter.on('submitPlacesCriteria', () => {
      if (this.pinnedMix) {
        this.pins[0].classList.remove('is-active')
        this.reelManagers[0].isPinned = false
        this.unPin(0)
        this.pinnedMix = false
      }
      this.page = 1
      this.launchSearch(true)
      Micromodal.close('places-criteria')
    })
  }

  /**
   * @function handleClearPlaces
   * Call @function launchSearch
   */
  handleClearPlaces (e) {
    this.placesClear.addEventListener('click', (e) => {
      e.preventDefault()
      EventEmitter.emit('clearPlacesCriteria')
      this.page = 1
      this.launchSearch(true)
    })
  }

  hasSelectedPlace () {
    if (document.querySelector('.o-js-regions-select-hiddens').innerHTML !== '') {
      return true
    }
  }

  /**
   * @function handleAutocomplete
   * Call @function launchSearch on autocomplete events
   */
  handleAutocomplete () {
    this.autocompletes.listenToKeyPress((autocompleteField, valueLength) => {
      const fieldType = autocompleteField.dataset.type

      // empty opposite text field if value is not empty but no item was selected
      if (fieldType === 'recipe' && this.autocomplete[1].value !== '' && !localStorage.getItem('pinWine')) {
        this.autocomplete[1].value = ''
        this.autocomplete[1].closest('.typeahead__container').classList.remove('cancel')
      }

      if (fieldType === 'wine' && this.autocomplete[0].value !== '' && !localStorage.getItem('pinRecipe')) {
        this.autocomplete[0].value = ''
        const hiddenFieldString = this.autocomplete[0].getAttribute('id').replace(/_s_fake/, '_s')
        document.getElementById(hiddenFieldString).value = ''
        this.autocomplete[0].closest('.typeahead__container').classList.remove('cancel')
      }

      if (valueLength >= 5) {
        this.page = 1
        this.shouldEmptyAutocompletes = false
        this.launchSearch(true)
      }
    })

    this.autocompletes.listenToTypeaheadSelect((autocompleteField) => {
      let fieldType = autocompleteField.dataset.type

      if (autocompleteField.value !== '') {
        // Unblock reel if already pinned (via typeahead only or via mix selection)
        if (this.pinByTypeaheadSelect || this.pinnedMix) {
          console.log('test')
          Utils.forEach(this.pins, (index, pin) => {
            let pinType = pin.dataset.type

            if (pinType === fieldType) {
              pin.classList.remove('is-active')
              this.reelManagers[index].isPinned = false
              this.slotPinned = false
              this.pinByTypeaheadSelect = false

              const hiddenFieldId = 'search_' + fieldType + '_id'
              document.getElementById(hiddenFieldId).value = ''
            }
          })

          this.pinnedMix = false
        }

        // Pin selected item
        if (fieldType === 'recipe' && autocompleteField.value) {
          this.shouldPinRecipe = true
          this.pinByTypeaheadSelect = true
          localStorage.setItem('pinRecipe', autocompleteField.value)
          if (this.searchBtns.length) {
            this.searchBtns[0].classList.add('is-blurred')
            this.resetFormElements('recipe')
          }
          this.shouldEmptyAutocompletes = false
        } else if (fieldType === 'wine' && autocompleteField.value) {
          this.shouldPinWine = true
          this.pinByTypeaheadSelect = true
          localStorage.setItem('pinWine', autocompleteField.value)
          if (this.searchBtns.length >= 2) {
            this.resetFormElements('wine')
          }
          this.shouldEmptyAutocompletes = false
        }

        this.page = 1
        this.launchSearch(true)
      } else {
        let index = 0
        if (fieldType === 'wine') {
          index = 1
        }

        this.pins[index].classList.remove('is-active')
        this.reelManagers[index].isPinned = false
        this.unPin(index)
      }
    })
  }

  handleMillesimeInput () {
    this.millesimeInput.addEventListener('input', () => {
      if (this.millesimeInput.value.trim().match(/[1|2]{1}[0-9]{3}/)) {
        this.page = 1
        this.launchSearch(true)
      }
    })

    this.millesimeInput.addEventListener('keypress', (e) => {
      // press enter key
      if (e.keyCode === 13) {
        this.millesimeInput.blur()
      }
    })
  }

  /**
   * @function listenToFavoriteAction
   * Listen to addFavorite and removeFavorite events (emitted in Favorites @class)
   * Set agreement_favorites of according data to true
   */
  listenToFavoriteEvents () {
    EventEmitter.on('addFavorite', () => {
      this.globalData[this.selectedMix.dataId].agreement_favorites = true
    })

    EventEmitter.on('removeFavorite', () => {
      this.globalData[this.selectedMix.dataId].agreement_favorites = false
    })
  }

  /**
   * @function handlePins
   * Listen to click on pins and call @function launchSearch
   * Handle @property slotPinned
   */
  handlePins () {
    Utils.forEach(this.pins, (index, pin) => {
      pin.addEventListener('click', () => {
        pin.classList.toggle('is-active')
        this.reelManagers[index].isPinned = !this.reelManagers[index].isPinned
        let hiddenFieldId = 'search_' + this.reelIdentifiers[index] + '_id'

        if (pin.classList.contains('is-active')) {
          this.slotPinned = true
          let selectedEl = this.reelManagers[index].el.querySelector('[data-mix-id="' + this.selectedMix.id + '"]')
          let selectedElId = selectedEl.dataset.id
          document.getElementById(hiddenFieldId).value = selectedElId
        } else {
          this.cleanUrl()
          this.unPin(index)
        }

        if (!this.reelManagers[0].isPinned || !this.reelManagers[1].isPinned) {
          this.page = 1
          this.launchSearch(true);
        }
      })
    })
  }

  unPin (index) {
    let hiddenFieldId = 'search_' + this.reelIdentifiers[index] + '_id'

    document.getElementById(hiddenFieldId).value = ''
    document.querySelector('input[name="search[' + this.reelIdentifiers[index] + '_s]"').value = ''
    this.pinByTypeaheadSelect = false

    if (index === 0) {
      this.cleanSelectedRecipe()
    } else if (index === 1) {
      this.cleanSelectedWine()
    }

    if (!this.reelManagers[0].isPinned && !this.reelManagers[1].isPinned) {
      this.slotPinned = false
    }

    if (!this.reelManagers[0].isPinned || !this.reelManagers[1].isPinned) {
      if (!this.resettedForm) {
        this.page = 1
        this.launchSearch(true)
      } else if (index === 1) {
        this.resettedForm = false
      }
    }
  }

  /**
   * @function handleResetBtn
   * Reset form, reset pins, clean hidden field and launch page 1 search
   * On click on reset btn
   */
  handleResetBtn () {
    this.resetBtn.addEventListener('click', (e) => {
      e.preventDefault()

      this.resetSearch()
    })
  }

  handleLangBtn () {
    this.langBtn.addEventListener('click', (e) => {
      e.preventDefault()
      const url = this.langBtn.getAttribute('href')

      this.resetSearch(() => {
        window.location = url
      })
    })
  }

  resetSearch (callback = null) {
    const request = new XMLHttpRequest()
    request.open('POST', this.resetBtn.getAttribute('href'))
    request.send()

    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status === 204) {
          EventEmitter.emit('resetSearch')
          this.resettedForm = true
          this.resetFormElements()

          Utils.forEach(this.pins, (index, pin) => {
            pin.classList.remove('is-active')
            this.reelManagers[index].isPinned = false
            this.slotPinned = false
            this.pinByTypeaheadSelect = false
            let hiddenFieldId = 'search_' + this.reelIdentifiers[index] + '_id'
            document.getElementById(hiddenFieldId).value = ''
          })
          this.selectGrape.resetBtnText()
          this.cleanUrl()

          this.filters = null
          this.resetBtn.classList.remove('is-visible')
          this.homeLogo.classList.remove('is-hidden')
          this.handleAgreementSuggestionsVisibleState()
          this.handleSearchButtonsActiveState()

          this.updateAvailableCount('wines')
          this.updateAvailableCount('recipes')

          localStorage.removeItem('selectedMixId')
          localStorage.removeItem('slotMachineDirection')
          localStorage.removeItem('slotMachinePosition')
          localStorage.removeItem('pinRecipe')
          localStorage.removeItem('pinWine')
          localStorage.removeItem('pinWineVintage')

          if (callback) {
            callback()
          }
        }
      }
    }
  }

  /**
   * @function resetForm
   * call function reset() on form
   * manually reset other elements
   */
  resetFormElements (type = null) {
    if (!type) {
      this.form.reset()
      Utils.forEach(this.autocomplete, (index, el) => {
        el.value = ''
      })
      Utils.forEach(this.form.querySelectorAll('input[type="checkbox"]'), (index, el) => {
        el.removeAttribute('checked')
      })

      if (this.searchBtns.length > 1) {
        this.searchBtns[0].classList.remove('is-blurred')
        this.searchBtns[1].classList.remove('show-millesime')
        this.searchBtns[1].classList.remove('is-blurred')
        this.millesimeInput.value = ''
      }
    } else if (type === 'recipe') {
      Utils.forEach(this.form.querySelectorAll('#recipe-criterias input[type="checkbox"]'), (index, el) => {
        el.removeAttribute('checked')
        el.checked = false
        el.classList.remove('is-checked')
      })
      EventEmitter.emit('resetSearch')
    } else if (type === 'wine') {
      Utils.forEach(this.form.querySelectorAll('#wine-criterias input[type="checkbox"]'), (index, el) => {
        el.removeAttribute('checked')
        el.checked = false
        el.classList.remove('is-checked')
      })
      Utils.forEach(this.form.querySelectorAll('#grape-criteria input[type="checkbox"]'), (index, el) => {
        el.removeAttribute('checked')
        el.checked = false
        el.classList.remove('is-checked')
      })
      this.selectGrape.resetBtnText()
    }
  }

  /**
   * @function handleReelsMove
   * Listen to goToNextSlot and goToPrevSlot events from @class ReelManager
   * Update @property selectedMix and call @function updateInfo
   * Optionnally load new page by calling @function launchSearch
   */
  handleReelsMove () {
    EventEmitter.on('goToNextSlot', () => {
      this.slotMachineDirection = 'next'
      if (!this.lastSlotMachineDirection) {
        this.lastSlotMachineDirection = 'next'
      }

      if (this.firstPageIsVisible) {
        this.slotMachinePosition = 'down'
        localStorage.setItem('slotMachinePosition', 'down')
      }

      this.reelManagers.forEach((reelManager, index) => {
        if (!reelManager.isPinned) {
          reelManager.reel.goToNext()

          let activeSlot = reelManager.el.querySelector('.o-js-slot-el.is-active')
          if (activeSlot) {
            activeSlot.classList.remove('is-active')
          }

          reelManager.reel.slots[reelManager.reel.selectedIndex].classList.add('is-active')

          this.selectedMix.id = parseInt(reelManager.reel.slots[reelManager.reel.selectedIndex].dataset.mixId)
          this.selectedMix.data = this.getSelectedMixData(this.selectedMix.id).data

          localStorage.setItem('selectedMixId', this.selectedMix.id)

          if (index === 0 || (index === 1 && this.reelManagers[0].isPinned)) {
            this.selectedMix.updateInfo()
          }

          if (reelManager.reel.selectedIndex <= 1) {
            if ((!this.slotPinned && index === 0) || (this.slotPinned)) {
              if (!this.firstPageIsVisible && this.slotMachinePosition === 'up') {
                this.pagingDirection = 'prev'
                if (this.lastSlotMachineDirection !== this.slotMachineDirection) {
                  this.page -= this.maxPagesVisible
                } else {
                  this.page -= 1
                }
              } else {
                this.pagingDirection = 'next'
                if (this.lastSlotMachineDirection !== this.slotMachineDirection) {
                  this.page += this.maxPagesVisible
                } else {
                  this.page += 1
                }
              }
              this.launchSearch()
            }
          }
        }
      })
    })

    EventEmitter.on('goToPrevSlot', () => {
      this.slotMachineDirection = 'prev'
      localStorage.setItem('slotMachineDirection', 'prev')
      if (!this.lastSlotMachineDirection) {
        this.lastSlotMachineDirection = 'prev'
      }

      if (this.firstPageIsVisible) {
        this.slotMachinePosition = 'up'
        localStorage.setItem('slotMachinePosition', 'up')
      }

      this.reelManagers.forEach((reelManager, index) => {
        if (!reelManager.isPinned) {
          reelManager.reel.goToPrev()

          let activeSlot = reelManager.el.querySelector('.o-js-slot-el.is-active')
          if (activeSlot) {
            activeSlot.classList.remove('is-active')
          }
          reelManager.reel.slots[reelManager.reel.selectedIndex].classList.add('is-active')

          this.selectedMix.id = parseInt(reelManager.reel.slots[reelManager.reel.selectedIndex].dataset.mixId)
          this.selectedMix.data = this.getSelectedMixData(this.selectedMix.id).data
          this.selectedMix.updateInfo()

          localStorage.setItem('selectedMixId', this.selectedMix.id)

          if (reelManager.reel.selectedIndex >= reelManager.reel.slots.length - 2) {
            if ((!this.slotPinned && index === 0) || (this.slotPinned)) {
              if (!this.firstPageIsVisible && this.slotMachinePosition === 'down') {
                this.pagingDirection = 'prev'

                if (this.lastSlotMachineDirection !== this.slotMachineDirection) {
                  this.page -= 2
                } else {
                  this.page -= 1
                }
              } else {
                this.pagingDirection = 'next'
                this.page += 1
              }
              this.launchSearch()
            }
          }
        }
      })
    })
  }

  /**
   * @function onResize
   * Call reelManager onResize @function
   */
  onResize () {
    this.reelManagers.forEach((reelManager) => {
      reelManager.onResize()
    })
  }

  /**
   * @function launchSearch
   * Get data from json file and call @function displayResults or @function displayNewPage
   *
   * @param {boolean} initial - search after form submission
   */
  launchSearch (initial = false) {
    // Clean autocomplete fields if no item was selected
    this.emptyAutocompletes()
    this.shouldEmptyAutocompletes = true

    let request = new XMLHttpRequest()
    console.log('search for page : ' + this.page)
    request.open('GET', `${this.searchRoute}.json?${Utils.serialize(document.querySelector('form'))}&page=${this.page}`, true)
    request.onload = () => {
      if (request.status >= 200 && request.status < 400) {
        const response = JSON.parse(request.responseText)
        this.data = response.data
        this.resetForm = response.reset_form
        console.log(this.data)
        // this.data.forEach((d) => {
        //   console.log(d.recipe.short_name)
        // })

        if (this.page === 1 && initial) {
          this.globalData = this.data
          this.firstPageIsVisible = true
          this.reelManagers[0].pagesDisplayed = 0
          this.reelManagers[1].pagesDisplayed = 0
          if (this.globalData[0] === undefined || this.globalData[0].wine.vintage !== '') {
            this.selectedWineVintage = false
          } else {
            this.selectedWineVintage = true
          }
        } else if (this.page === 1) {
          this.firstPageIsVisible = true
        }

        this.addDataToGlobal()

        if (this.page === 1 && this.data.length && initial) {
          this.displayResults()

          Utils.forEach(this.searchBtn, (index, searchBtn) => {
            searchBtn.removeAttribute('disabled')
          })
        } else if (this.data.length) {
          this.displayNewPage()
        } else if (this.page === 1 && !this.data.length) {
          console.log('Aucun résultat')

          Utils.forEach(this.searchBtn, (index, searchBtn) => {
            searchBtn.setAttribute('disabled', 'disabled')
          })

          if (this.resetForm) {
            this.resetBtn.classList.add('is-visible')
            this.homeLogo.classList.add('is-hidden')
          }

          if (document.getElementById('wine-criterias').classList.contains('is-open')) {
            this.setAvailableCountToNone('wines')
          } else if (document.getElementById('recipe-criterias').classList.contains('is-open')) {
            this.setAvailableCountToNone('recipes')
          } else {
            this.setAvailableCountToNone('wines')
            this.setAvailableCountToNone('recipes')
          }
        }

        this.filters = response.filters

        // // Search buttons is-active state
        this.handleSearchButtonsActiveState()

        // Agreement suggestions is-visible state
        this.handleAgreementSuggestionsVisibleState()
      }
    }

    request.send()
  }

  displayStoredResults (page, mixId) {
    let machinePosition = localStorage.getItem('slotMachinePosition')
    if (!machinePosition) {
      machinePosition = 'down'
    }

    let machineDirection = 'next'
    if (machinePosition === 'up') {
      machineDirection = 'prev'
    }

    // Set pages numbers to recover
    let pages = [page - 1, page, page + 1]
    if (page === 1) {
      pages = [page, page + 1, page + 2]
    }

    this.selectedMix.hideResults()

    // Manage pinned elements
    const recipeIdValue = document.getElementById('search_recipe_id').value
    const wineIdValue = document.getElementById('search_wine_id').value

    if (recipeIdValue) {
      this.pins[0].classList.add('is-active')
      this.slotPinned = true
      this.reelManagers[0].isPinned = true
    }
    if (wineIdValue) {
      this.pins[1].classList.add('is-active')
      this.slotPinned = true
      this.reelManagers[1].isPinned = true
    }

    if (localStorage.getItem('pinRecipe')) {
      if (this.searchBtns.length && this.autocomplete.length) {
        this.searchBtns[0].classList.add('is-blurred')
        this.autocomplete[0].value = localStorage.getItem('pinRecipe')
        this.autocomplete[0].closest('.typeahead__container').classList.add('cancel')
        this.pinByTypeaheadSelect = true
      }
    }

    if (localStorage.getItem('pinWine') && this.autocomplete.length > 1) {
      if (this.searchBtns.length > 1) {
        if (localStorage.getItem('pinWineVintage') === 'true') {
          this.searchBtns[1].classList.add('show-millesime')
        } else {
          this.searchBtns[1].classList.add('is-blurred')
        }
        this.autocomplete[1].value = localStorage.getItem('pinWine')
        this.autocomplete[1].closest('.typeahead__container').classList.add('cancel')
        this.pinByTypeaheadSelect = true
      }
    }

    // Load pages
    const loadPages = async () => {
      for (let i = 0; i < pages.length; i++) {
        let noSession = false
        if (i > 0) {
          noSession = true
        }
        await this.launchSearchForPage(pages[i], i, machineDirection, mixId, noSession)
      }

      this.displayStoredSelectedMix(mixId, machinePosition)
      this.page = pages[pages.length - 1]
      this.handleSearchButtonsActiveState()
    }

    loadPages()

    this.updateAvailableCount('wines')
    this.updateAvailableCount('recipes')

    this.resetBtn.classList.add('is-visible')
    this.homeLogo.classList.add('is-hidden')
  }

  launchSearchForPage (p, index, machineDirection, mixId, noSession) {
    return new Promise((resolve) => {
      let request = new XMLHttpRequest()
      if (noSession) {
        request.open('GET', `${this.searchRoute}.json?${Utils.serialize(document.querySelector('form'))}&page=${p}&no_session=1`, true)
      } else {
        request.open('GET', `${this.searchRoute}.json?${Utils.serialize(document.querySelector('form'))}&page=${p}`, true)
      }

      request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
          console.log('search for page : ' + p)
          const response = JSON.parse(request.responseText)
          this.data = response.data
          console.log(this.data)
          this.filters = response.filters

          this.reelManagers.forEach((reelManager) => {
            if (index === 0) {
              reelManager.reel.removeOldSlots()
            }
            reelManager.reel.createNewPageSlots(this.data, p, machineDirection, 'next', mixId)
            reelManager.reel.selectedIndex = 0
            reelManager.pagesDisplayed += 1
            reelManager.reel.resetSlots()
          })

          this.globalData = this.globalData.concat(this.data)

          this.handleAgreementSuggestionsVisibleState()

          resolve()
        }
      }

      request.send()
    })
  }

  displayStoredSelectedMix (mixId, machinePosition) {
    let selectedMixUpdated = false

    this.reelManagers.forEach((reelManager) => {
      const activeSlot = reelManager.el.querySelector('.o-js-slot-el.is-active')
      reelManager.reel.selectedIndex = Utils.index(activeSlot)
      this.selectedMix.id = this.reelManagers[0].reel.slots[this.reelManagers[0].reel.selectedIndex].dataset.mixId

      reelManager.reel.animateOneLoop(reelManager.reel.loopDuration, () => {
        let index = this.getSelectedMixData(this.selectedMix.id).id

        if (machinePosition === 'down') {
          index *= -1
        }

        reelManager.reel.goToIndex(index, reelManager.reel.loopDuration, () => {
          reelManager.reel.selectedIndex = Utils.index(activeSlot)
          reelManager.reel.setIndexesToShow()

          if (!selectedMixUpdated) {
            this.selectedMix.id = this.reelManagers[0].reel.slots[this.reelManagers[0].reel.selectedIndex].dataset.mixId
            this.selectedMix.data = this.getSelectedMixData(this.selectedMix.id).data
            this.selectedMix.updateInfo()
            this.selectedMix.showResults()

            selectedMixUpdated = true
          }
        })
      })
    })
  }

  /**
   * @function displayResults
   * Close opened modal
   * Call @function displayResults from @class ReelManager
   * Update @property selectedMix and call @function updateInfo
   */
  displayResults () {
    if (document.getElementById('wine-criterias').classList.contains('is-open')) {
      this.updateAvailableCount('wines')
    } else if (document.getElementById('recipe-criterias').classList.contains('is-open')) {
      this.updateAvailableCount('recipes')
    } else {
      this.updateAvailableCount('wines')
      this.updateAvailableCount('recipes')
    }

    this.selectedMix.hideResults()
    this.selectedMix.infoUpdated = false

    this.reelManagers.forEach((reelManager, index) => {
      if (!reelManager.isPinned) {
        reelManager.displayResults(this.data).then(() => {
          reelManager.pagesDisplayed += 1
          reelManager.reel.slots[reelManager.reel.selectedIndex].classList.add('is-active')

          if (!this.selectedMix.infoUpdated) {
            this.selectedMix.id = this.globalData[0].id
            this.selectedMix.data = this.globalData[0]
            this.selectedMix.updateInfo()
            this.selectedMix.showResults()

            localStorage.setItem('selectedMixId', this.selectedMix.id)

            this.selectedMix.infoUpdated = true
          }

          // Handle search with pin recipe or wine
          if (index === 0) {
            if (this.recipeId.value || this.shouldPinRecipe) {
              reelManager.isPinned = true
              this.pins[0].classList.add('is-active')
              this.slotPinned = true
              this.shouldPinRecipe = false
            }
          } else if (index === 1) {
            if (this.wineId.value || this.shouldPinWine) {
              reelManager.isPinned = true
              this.pins[1].classList.add('is-active')
              this.slotPinned = true
              this.shouldPinWine = false
            }
          }
        })
      }
    })

    if (this.reelManagers[0].isPinned && this.reelManagers[1].isPinned) {
      this.selectedMix.id = this.reelManagers[0].reel.slots[this.reelManagers[0].reel.selectedIndex].dataset.mixId
      this.selectedMix.dataId = this.getIndexData(this.reelManagers[0].reel.selectedIndex, this.reelManagers[0].reel.midIndex)
      this.selectedMix.data = this.globalData[this.selectedMix.dataId]
      this.selectedMix.updateInfo()
      this.selectedMix.showResults()

      this.selectedMix.infoUpdated = true
    }

    // Show millesime or blur filters according to vintage field
    if (localStorage.getItem('pinWine')) {
      if (this.searchBtns.length > 1) {
        if (this.globalData[0].wine.vintage !== '') {
          this.searchBtns[1].classList.add('show-millesime')
          localStorage.setItem('pinWineVintage', true)
        } else {
          this.searchBtns[1].classList.add('is-blurred')
          localStorage.setItem('pinWineVintage', false)
        }
        this.resetFormElements('wine')
      }
    }

    if (this.resetForm) {
      this.resetBtn.classList.add('is-visible')
      this.homeLogo.classList.add('is-hidden')
    } else {
      this.resetBtn.classList.remove('is-visible')
      this.homeLogo.classList.remove('is-hidden')
    }
  }

  /**
   * @function displayNewPage
   */
  displayNewPage () {
    let pageRemoved = false

    this.reelManagers.forEach((reelManager, index) => {
      if (!reelManager.isPinned) {
        reelManager.reel.createNewPageSlots(this.data, this.page, this.slotMachineDirection, this.pagingDirection)
        this.lastSlotMachineDirection = this.slotMachineDirection
        reelManager.pagesDisplayed += 1
        reelManager.reel.resetSlots()
        reelManager.reel.setIndexesToShow()

        if (reelManager.pagesDisplayed > this.maxPagesVisible) {
          reelManager.reel.removePageSlots(this.slotMachineDirection, this.lastSlotMachineDirection, this.firstPageIsVisible, this.page)
          reelManager.pagesDisplayed -= 1
          pageRemoved = true
        }
      }
    })

    if (this.firstPageIsVisible && this.slotMachineDirection === this.lastSlotMachineDirection && pageRemoved && this.pagingDirection === 'next') {
      this.firstPageIsVisible = false
    }
  }

  /**
   * @function addDataToGlobal
   * Add data to global if mix doesn't already stored
   */
  addDataToGlobal () {
    this.data.forEach((d) => {
      if (this.getSelectedMixData(d.id) === null) {
        this.globalData.push(d)
      }
    })
  }

  /**
   * @function getSelectedMixData
   * Get data in globalData @property corresponding to a mix id
   * @params {int} mixId
   */
  getSelectedMixData (mixId) {
    mixId = parseInt(mixId)

    for (let i = 0; i < this.globalData.length; i++) {
      if (this.globalData[i].id === mixId) {
        return {
          'id': i,
          'data': this.globalData[i]
        }
      }
    }

    return null
  }

  /**
   * @function handleSearchButtonsActiveState
   * Add or remove is-active class on search-buttons[data-filter]
   */
  handleSearchButtonsActiveState () {
    if (this.filters === null) {
      Utils.forEach(this.form.querySelectorAll('[data-filter]'), (index, el) => {
        el.classList.remove('is-active')
      })
    }

    for (let key in this.filters) {
      if (key !== 'recipe_s_query' && key !== 'wine_s_query' && key !== 'recipe_id_query' && key !== 'wine_id_query') {
        let el = document.querySelector('[data-filter="' + key + '"]')
        if (el !== null) {
          if (this.filters[key]) {
            el.classList.add('is-active')
          } else {
            el.classList.remove('is-active')
          }
        }
      }
    }
  }

  cleanSelectedRecipe () {
    if (this.searchBtns.length && this.autocomplete.length) {
      this.searchBtns[0].classList.remove('is-blurred')
      this.autocomplete[0].value = ''
      this.autocomplete[0].closest('.typeahead__container').classList.remove('cancel')
    }
    localStorage.removeItem('pinRecipe')
  }

  cleanSelectedWine () {
    if (this.searchBtns.length > 1 && this.autocomplete.length > 1) {
      this.searchBtns[1].classList.remove('show-millesime')
      this.searchBtns[1].classList.remove('is-blurred')
      this.millesimeInput.value = ''
      this.autocomplete[1].value = ''
      this.autocomplete[1].closest('.typeahead__container').classList.remove('cancel')
    }
    localStorage.removeItem('pinWine')
    localStorage.removeItem('pinWineVintage')
  }

  emptyAutocompletes () {
    if (this.shouldEmptyAutocompletes) {
      this.autocomplete.forEach((autocomplete) => {
        if (autocomplete.dataset.type === 'recipe' && autocomplete.value !== '' && !localStorage.getItem('pinRecipe')) {
          autocomplete.value = ''
          const nodeId = autocomplete.getAttribute('id')
          const hiddenFieldString = nodeId.replace(/_s_fake/, '_s')
          document.getElementById(hiddenFieldString).value = ''
          autocomplete.closest('.typeahead__container').classList.remove('cancel')
        }

        if (autocomplete.dataset.type === 'wine' && autocomplete.value !== '' && !localStorage.getItem('pinWine')) {
          autocomplete.value = ''
          autocomplete.closest('.typeahead__container').classList.remove('cancel')
        }
      })
    }
  }

  /**
   * @function handleAgreementSuggestionsVisibleState
   * Hide or show agreement suggestions panel
   */
  handleAgreementSuggestionsVisibleState () {
    if (this.filters === null) {
      this.agreementSuggestions.classList.add('is-visible')
      this.el.classList.remove('is-visible')
      this.searchResults.classList.remove('is-visible')
    } else if (this.filters.recipe_any || this.filters.wine_any || this.filters.recipe_s_query || this.filters.wine_s_query || this.filters.recipe_id_query || this.filters.wine_id_query) {
      this.agreementSuggestions.classList.remove('is-visible')
      this.el.classList.add('is-visible')
      this.searchResults.classList.add('is-visible')
    } else {
      this.agreementSuggestions.classList.add('is-visible')
      this.el.classList.remove('is-visible')
      this.searchResults.classList.remove('is-visible')
    }
  }

  /**
   * @function fillLoopBeforeSearch
   * Make sure one complete loop is visible before search animation
   */
  fillLoopBeforeSearch () {
    this.reelManagers.forEach((reelManager) => {
      let indexesToShow = []
      for (let i = 0; i < reelManager.reel.slotsPerReel / 2; i++) {
        indexesToShow.push(reelManager.reel.selectedIndex + i)
      }
      for (let i = 1; i <= reelManager.reel.slotsPerReel / 2; i++) {
        indexesToShow.push(reelManager.reel.selectedIndex - i)
      }

      reelManager.reel.setSlotsOpacityWithIndexes(1, indexesToShow)
    })
  }

  updateAvailableCount (type) {
    // Update available results count in filter's popin
    // See doc/index.md part index.md#recherche-par-filtre
    const request = new XMLHttpRequest()
    const modals = document.querySelectorAll(`.o-js-${type} .o-js-filter-banner-total`)

    request.open('GET', `${type}/count_available_${type}.json?${Utils.serialize(document.querySelector('form'))}`, true)

    request.send(null)

    request.onload = () => {
      if (request.status >= 200 && request.status < 400) {
        const response = JSON.parse(request.responseText)
        Utils.forEach(modals, (index, modalFilterBannerTotalText) => {
          modalFilterBannerTotalText.innerHTML = response.data.count
        })
      }
    }
  }

  setAvailableCountToNone (type) {
    const modals = document.querySelectorAll(`.o-js-${type} .o-js-filter-banner-total`)

    Utils.forEach(modals, (index, modalFilterBannerTotalText) => {
      modalFilterBannerTotalText.innerHTML = '0'
    })
  }

  /**
   * @function setMixRate
   * Update and display mix rate
   */
  setMixRate (rate) {
    let stars = ''

    for (let i = 0; i < rate; i++) {
      stars += this.filledStar
    }

    for (let i = rate; i < 4; i++) {
      stars += this.unFilledStar
    }

    this.stars.innerHTML = stars
  }

  getIndexData (selectedIndex, midIndex) {
    let index = 0

    if (selectedIndex < midIndex) {
      index = Math.abs(this.globalData.length - 1 - selectedIndex)
    } else if (selectedIndex > midIndex) {
      index = Math.abs(selectedIndex - midIndex)
    }

    return index
  }
}
